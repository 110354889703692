import { Component, ElementRef, OnInit, ViewChild, KeyValueDiffers, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Browser, Map, map, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import { MapsService } from '../services/maps.service';
import { MarkerService } from './shared/marker.service';
const moment = require('moment');
declare var $: any;

import * as LHeat from 'leaflet.heat';

import 'leaflet.heat/dist/leaflet-heat.js';
import 'leaflet-arrowheads';
import { addressPoints } from '../../assets/heat';

import 'leaflet-heatmap';
import { ChartOptions } from '../dashboard/dashboard.component';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit, DoCheck {
  formulario: FormGroup;
  dateForm: FormGroup;
  colaboradorForm: FormGroup;

  aps = [];

  client = JSON.parse(localStorage.getItem('currentClient'));
  a;
  floors = [];

  points = [];
  heatmap = [];

  imageBounds: any;

  userText =
    'group' in JSON.parse(localStorage.getItem('currentUser')).user
      ? JSON.parse(localStorage.getItem('currentUser')).user.group.text
      : '';
  validateAreas: boolean = false;
  colors = ['green', 'blue', 'red', 'orange', 'yellow', 'grey', 'violet', 'gold', 'green', 'blue'];
  circle = [];

  connecteds = [];
  detecteds = [];

  dataSelecionada: string = moment()
    .set({ hour: moment().hour(), minute: 0 })
    .format('YYYY-MM-DDTHH:mm:ss');

  validateConnecteds: boolean = true;
  validateDetecteds: boolean = false;
  validateCaminhada: boolean = false;

  floorAtual: any = '';

  steps = [];
  macs = [];

  allDateInput: boolean = false;

  // Conexões
  chartOptionsConnections: Partial<ChartOptions>;
  loadingConnections: boolean = false;
  validateConnections: boolean = false;

  validateSteps: boolean = true;

  stepsArrows: any = [];

  initialDate = moment()
    .subtract(7, 'day')
    .format()
    .split('T')[0];
  endDate = moment()
    .format()
    .split('T')[0];

  private map;

  private differ: any;

  mapOn: boolean = false;
  mainZoom: number = 18.5;
  radius: number = 40;

  private readonly STEPS_LIMIT = 10;
  private currentStepIndex = 0;
  hasMoreSteps = false;
  allSteps = []; // Armazena todos os steps
  hasPreviousSteps = false;

  loadingSteps = false;

  constructor(
    private markerService: MarkerService,
    private mapsService: MapsService,
    private formBuilder: FormBuilder,
    private differs: KeyValueDiffers
  ) {
    this.differ = this.differs.find({}).create();

    this.chartOptionsConnections = {
      series: [],
      chart: {
        height: 220,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: 'authenticatednew',
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              }
            },
            svg: {
              filename: 'authenticatednew'
            },
            png: {
              filename: 'authenticatednew'
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#F26522', '#6d6e71', '#672c0f'],
      stroke: {
        curve: 'straight',
        width: 1.5
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 10
      }
      // yaxis: [
      //   {
      //     seriesName: 'Experiência',
      //     show: true,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0) + '%';
      //       }
      //     },
      //   },
      //   {
      //     seriesName: '2.4',
      //     show: false,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0);
      //       }
      //     },
      //   },
      //   {
      //     seriesName: '2.4',
      //     show: true,
      //     opposite: true,
      //     labels:{
      //       formatter: function(val: number): string {
      //         return val.toFixed(0);
      //       }
      //     },
      //     max: 400,
      //     min: 100
      //   }
      // ]
    };

    this.colaboradorForm = this.formBuilder.group({
      mac: ['', Validators.required],
      nome: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.inicializarForm();
    this.getApConnections();

    $(document).ready(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  ngDoCheck() {
    if (this.mapOn) {
      if (this.map._zoom !== this.mainZoom) {
        if (this.heatmap.length > 0) {
          if (this.map._zoom == 20) {
            this.heatmap[0].setOptions({
              radius: 70
            });
          } else if (this.map._zoom == 19) {
            this.heatmap[0].setOptions({
              radius: 40
            });
          } else if (this.map._zoom == 18) {
            this.heatmap[0].setOptions({
              radius: 30
            });
          } else if (this.map._zoom == 17) {
            this.heatmap[0].setOptions({
              radius: 20
            });
          } else if (this.map._zoom == 16) {
            this.heatmap[0].setOptions({
              radius: 10
            });
          }
        }

        this.mainZoom = this.map._zoom;
      }
    }
  }

  ngAfterViewInit() {
    this.getConnecteds();

    let lat;
    let lng;

    if (this.client) {
      lat = (this.client.coordinates.East + this.client.coordinates.West) / 2;
      lng = (this.client.coordinates.North + this.client.coordinates.South) / 2;

      this.initMap(lat, lng, this.client);
    }
  }

  initMap(lat, lng, client): void {
    this.map = L.map('map', {
      center: [lng, lat],
      zoom: 19
    });

    this.mapOn = true;

    const tiles = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      maxZoom: 20,
      minZoom: 17,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    this.imageBounds = [
      [client.coordinates.North, client.coordinates.West],
      [client.coordinates.South, client.coordinates.East]
    ];

    var imageUrl = `../../assets/plant-info/${this.client.radio_prefix.toLowerCase()}/${
      this.client.Floors[0].name
    }.png`;
    L.imageOverlay(imageUrl, this.imageBounds).addTo(this.map);

    this.getFloors();
  }

  getFloors() {
    let lengthFloors = Object.keys(this.client.Floors).length;
    for (let i = 0; i < lengthFloors; i++) {
      this.floors.push(this.client.Floors[i]);
      this.floorAtual = this.floors[0];
    }
  }

  changeImage(floor, update?: boolean) {
    let indexFloor = this.floors.findIndex(object => {
      return object.name === floor;
    });

    this.floorAtual = floor;

    update = true;

    if (update) {
      var imageUrl = `../../assets/plant-info/${this.client.radio_prefix.toLowerCase()}/${floor}.png`;

      L.imageOverlay(imageUrl, this.imageBounds).addTo(this.map);
    }

    for (let i = 0; i < this.points.length; i++) {
      this.map.removeLayer(this.points[i]);
    }
    this.points = [];

    for (let i = 0; i < this.heatmap.length; i++) {
      this.map.removeLayer(this.heatmap[i]);
    }

    this.heatmap = [];

    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }

    for (let i = 0; i < this.circle.length; i++) {
      this.map.removeLayer(this.circle[i]);
    }

    this.stepsArrows = [];

    this.getApsCoords(indexFloor);
  }

  getStepsByMac() {
    // Limpa apenas as setas antigas
    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }
    this.stepsArrows = [];

    this.mapsService
      .getStepsByMac(this.client.controller_id, this.initialDate, this.endDate, this.formulario.controls['mac'].value)
      .subscribe(
        data => {
          this.allSteps = Array.isArray(data) ? data : [];
          this.currentStepIndex = 0;
          this.hasMoreSteps = this.allSteps.length > this.STEPS_LIMIT;
          this.hasPreviousSteps = false;

          // Pega apenas os primeiros 15 steps
          this.steps = this.allSteps.slice(0, this.STEPS_LIMIT);
          this.drawSteps();
        },
        error => {
          console.error('Erro ao buscar dados:', error);
        }
      );
  }

  showNextSteps() {
    this.currentStepIndex += this.STEPS_LIMIT;

    // Limpa as setas existentes
    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }
    this.stepsArrows = [];

    // Pega os próximos 15 steps
    this.steps = this.allSteps.slice(this.currentStepIndex, this.currentStepIndex + this.STEPS_LIMIT);

    // Atualiza o status dos botões
    this.hasMoreSteps = this.currentStepIndex + this.STEPS_LIMIT < this.allSteps.length;
    this.hasPreviousSteps = this.currentStepIndex > 0;

    // Desenha os novos steps
    this.drawSteps();
  }

  showPreviousSteps() {
    this.currentStepIndex -= this.STEPS_LIMIT;

    // Limpa as setas existentes
    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }
    this.stepsArrows = [];

    // Pega os 15 steps anteriores
    this.steps = this.allSteps.slice(this.currentStepIndex, this.currentStepIndex + this.STEPS_LIMIT);

    // Atualiza o status dos botões
    this.hasMoreSteps = this.currentStepIndex + this.STEPS_LIMIT < this.allSteps.length;
    this.hasPreviousSteps = this.currentStepIndex > 0;

    // Desenha os novos steps
    this.drawSteps();
  }

  // Método separado para desenhar os steps
  drawSteps() {
    if (this.steps.length > 1) {
      const pathMap = new Map();
      let aux = this.currentStepIndex > 0 ? this.currentStepIndex - 1 : 0;

      // Encontra o primeiro e último AP dos passos visíveis
      const firstStep = this.steps[0];
      const lastStep = this.steps[this.steps.length - 1];

      let firstAp = this.aps.find(ap => {
        const apName = ap.name.toLowerCase();
        const stepAp = firstStep.associeted_ap.toLowerCase();
        return apName.includes(`--${stepAp}`) || apName.includes(`${stepAp}`);
      });

      let lastAp = this.aps.find(ap => {
        const apName = ap.name.toLowerCase();
        const stepAp = lastStep.associeted_ap.toLowerCase();
        return apName.includes(`--${stepAp}`) || apName.includes(`${stepAp}`);
      });

      // Adiciona marcadores de início e fim
      if (firstAp && 'map' in firstAp) {
        const width = this.client.Floors[0].Width;
        const height = this.client.Floors[0].Height;
        const heightC = this.client.coordinates.North - this.client.coordinates.South;
        const widthC = this.client.coordinates.East - this.client.coordinates.West;

        const apCord1 = (firstAp.map.y * heightC) / height;
        const apCord2 = (firstAp.map.x * widthC) / width;
        const lat1 = this.client.coordinates.North - apCord1;
        const lon1 = this.client.coordinates.West + apCord2;

        const startMarker = L.circleMarker([lat1, lon1], {
          radius: 4, // Bolinha menor
          fillColor: '#32CD32', // Verde vívido
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 1
        });

        this.stepsArrows.push(startMarker);
        startMarker.addTo(this.map);
      }

      if (lastAp && 'map' in lastAp) {
        const width = this.client.Floors[0].Width;
        const height = this.client.Floors[0].Height;
        const heightC = this.client.coordinates.North - this.client.coordinates.South;
        const widthC = this.client.coordinates.East - this.client.coordinates.West;

        const apCord1 = (lastAp.map.y * heightC) / height;
        const apCord2 = (lastAp.map.x * widthC) / width;
        const lat1 = this.client.coordinates.North - apCord1;
        const lon1 = this.client.coordinates.West + apCord2;

        const endMarker = L.circleMarker([lat1, lon1], {
          radius: 4, // Bolinha menor
          fillColor: '#FF0000', // Vermelho vívido
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 1
        });

        this.stepsArrows.push(endMarker);
        endMarker.addTo(this.map);
      }

      for (let i = 0; i < this.steps.length - 1; i++) {
        const currentAp = this.aps.find(ap => {
          const apName = ap.name.toLowerCase();
          const stepAp =
            i === 0 && this.currentStepIndex > 0
              ? this.allSteps[this.currentStepIndex - 1].associeted_ap.toLowerCase()
              : this.steps[i].associeted_ap.toLowerCase();
          return apName.includes(`--${stepAp}`) || apName.includes(`${stepAp}`);
        });

        const nextAp = this.aps.find(ap => {
          const apName = ap.name.toLowerCase();
          const stepAp = this.steps[i + 1].associeted_ap.toLowerCase();
          return apName.includes(`--${stepAp}`) || apName.includes(`${stepAp}`);
        });

        if (currentAp && nextAp && 'map' in currentAp && 'map' in nextAp) {
          const width = this.client.Floors[0].Width;
          const height = this.client.Floors[0].Height;
          const heightC = this.client.coordinates.North - this.client.coordinates.South;
          const widthC = this.client.coordinates.East - this.client.coordinates.West;

          // Coordenadas do AP atual
          const apCord1 = (currentAp.map.y * heightC) / height;
          const apCord2 = (currentAp.map.x * widthC) / width;
          const lat1 = this.client.coordinates.North - apCord1;
          const lon1 = this.client.coordinates.West + apCord2;

          // Coordenadas do próximo AP
          const apCord3 = (nextAp.map.y * heightC) / height;
          const apCord4 = (nextAp.map.x * widthC) / width;
          const lat2 = this.client.coordinates.North - apCord3;
          const lon2 = this.client.coordinates.West + apCord4;

          const forwardKey = `${currentAp.name}->${nextAp.name}`;
          const backwardKey = `${nextAp.name}->${currentAp.name}`;

          // Verifica se é um retorno para um AP já visitado
          let isBackward = false;
          let currentKey = forwardKey;

          // Procura no histórico se já visitamos este AP
          for (let j = 0; j < i; j++) {
            const previousAp = this.aps.find(ap => {
              const apName = ap.name.toLowerCase();
              const stepAp = this.steps[j].associeted_ap.toLowerCase();
              return apName.includes(`--${stepAp}`) || apName.includes(`${stepAp}`);
            });

            if (previousAp && previousAp.name === nextAp.name) {
              isBackward = true;
              currentKey = backwardKey;
              break;
            }
          }

          // Cria uma chave única para cada passo
          const uniqueKey = `${currentKey}-${aux}`;

          // Inicializa o caminho
          pathMap.set(uniqueKey, {
            times: [
              {
                start: moment(this.steps[i].timestamp).format('HH:mm:ss'),
                end: moment(this.steps[i + 1].timestamp).format('HH:mm:ss')
              }
            ],
            stepNumber: aux + 1,
            isBackward
          });

          const offset = isBackward ? 0.00002 : -0.00002;

          // Calcula os pontos ajustados para a seta parar na borda do círculo
          const raio = 0.00004; // Ajuste este valor conforme necessário

          // Calcula o vetor direção
          const dx = lat2 - lat1;
          const dy = lon2 + offset - (lon1 + offset);
          const length = Math.sqrt(dx * dx + dy * dy);

          // Normaliza o vetor
          const nx = dx / length;
          const ny = dy / length;

          // Ajusta os pontos finais para parar na borda do círculo
          const adjustedLat1 = lat1 + nx * raio;
          const adjustedLon1 = lon1 + offset + ny * raio;
          const adjustedLat2 = lat2 - nx * raio;
          const adjustedLon2 = lon2 + offset - ny * raio;

          // Cria a linha com seta usando os pontos ajustados
          const arrow = L.polyline(
            [
              [adjustedLat1, adjustedLon1],
              [adjustedLat2, adjustedLon2]
            ],
            {
              color: '#FF0000',
              weight: 3,
              opacity: 1
            }
          ).arrowheads({
            size: '12px',
            frequency: 1,
            fill: true,
            yawn: 45
          });

          this.stepsArrows.push(arrow);
          arrow.addTo(this.map);

          // Tooltips
          const pathInfo = pathMap.get(uniqueKey);
          const tooltipContent = `Passo ${pathInfo.stepNumber}`;
          const fullTooltipContent = `Passo ${pathInfo.stepNumber}<br>${pathInfo.times[0].start} → ${pathInfo.times[0].end}`;

          // Cria um ID único para este tooltip
          const tooltipId = `tooltip-${aux}`;

          const tooltip = arrow.bindTooltip(tooltipContent, {
            permanent: true,
            direction: isBackward ? 'left' : 'right',
            className: tooltipId // Adiciona uma classe única ao tooltip
          });

          // Eventos para mostrar/esconder horários na seta
          arrow.on('mouseover', function(e) {
            arrow.unbindTooltip();
            arrow.bindTooltip(fullTooltipContent, {
              permanent: true,
              direction: isBackward ? 'left' : 'right',
              className: tooltipId
            });
          });

          arrow.on('mouseout', function(e) {
            arrow.unbindTooltip();
            arrow.bindTooltip(tooltipContent, {
              permanent: true,
              direction: isBackward ? 'left' : 'right',
              className: tooltipId
            });
          });

          // Eventos para o próprio tooltip
          arrow.on('tooltipopen', function(e) {
            const tooltipElement = document.querySelector(`.${tooltipId}`);
            if (tooltipElement) {
              tooltipElement.addEventListener('mouseenter', () => {
                arrow.unbindTooltip();
                arrow.bindTooltip(fullTooltipContent, {
                  permanent: true,
                  direction: isBackward ? 'left' : 'right',
                  className: tooltipId
                });
              });

              tooltipElement.addEventListener('mouseleave', () => {
                arrow.unbindTooltip();
                arrow.bindTooltip(tooltipContent, {
                  permanent: true,
                  direction: isBackward ? 'left' : 'right',
                  className: tooltipId
                });
              });
            }
          });

          aux++;
        }
      }

      // Ajusta o zoom
      const bounds = L.latLngBounds(this.stepsArrows.map(arrow => arrow.getBounds()));
      this.map.fitBounds(bounds, { padding: [50, 50] });
    }
  }

  getApConnections() {
    this.loadingConnections = true;
    this.validateConnections = false;
    this.mapsService
      .getApsConnections(
        this.client.radio_prefix,
        this.dateForm.controls['initialDate'].value,
        this.dateForm.controls['endDate'].value
      )
      .subscribe(data => {
        // if (data.length > 0){
        this.getConnectionsData(data);
        // }
      })
      .add(() => {
        this.loadingConnections = false;
      });
  }

  getConnectionsData(data) {
    this.chartOptionsConnections.series = [];
    this.chartOptionsConnections.xaxis.categories = data.date;
    this.chartOptionsConnections.series.push({ name: 'Detectados', data: data.detected });

    this.validateConnections = true;
  }

  reloadChart() {
    if (!this.loadingConnections) {
      this.loadingConnections = true;

      setTimeout(() => {
        this.loadingConnections = false;
      }, 500);
    }
  }

  getAps() {
    let radioPrefix = this.client.radio_prefix;
    this.mapsService.getRadiosByRadioPrefix(radioPrefix).subscribe(data => {
      this.aps = data;
      this.getApsCoords(0);
    });
  }

  getApsCoords(indexAtual) {
    let floorAtual = this.client.Floors[indexAtual];
    const width = floorAtual.Width;
    const height = floorAtual.Height;
    const heightC = this.client.coordinates.North - this.client.coordinates.South;
    const widthC = this.client.coordinates.East - this.client.coordinates.West;
    let latlngList = [];
    this.heatmap = [];

    this.aps.forEach((element, index) => {
      if ('map' in element) {
        if (Number(element.map.floor) == Number(indexAtual)) {
          var ap_map = element.map;
          let apCord1 = (ap_map.y * heightC) / height;
          let apCord2 = (ap_map.x * widthC) / width;
          let toolTip;

          const lat = this.client.coordinates.North - apCord1;
          const lon = this.client.coordinates.West + apCord2;

          // Adiciona o círculo de hitbox apenas no modo caminhada
          if (this.validateCaminhada) {
            const hitboxCircle = L.circle([lat, lon], {
              radius: 2,
              color: 'transparent',
              fillColor: 'transparent',
              fillOpacity: 0,
              weight: 0,
              interactive: false // Desabilita interações com o círculo
            });
            this.points.push(hitboxCircle);
            hitboxCircle.addTo(this.map);
          }

          const iconRetinaUrl = 'assets/favicon.ico';
          const iconUrl = 'assets/favicon.ico';
          let iconDefault = L.icon({
            iconRetinaUrl,
            iconUrl,
            iconSize: [18, 18],
            iconAnchor: [16, 14],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41]
          });

          toolTip = `<b>Nome:</b> ${element.name.split('--')[1]}<br>` + `<b>IP:</b> ${element.ip}<br>`;

          // Adiciona informações específicas do modo
          if (this.validateConnecteds) {
            let connecteds = this.connecteds.find(el => el.ap_id == element.ip);
            toolTip += `<b>Conectados:</b> ${connecteds ? String(connecteds.total).split('.')[0] : '0'}<br>`;
            if (connecteds) {
              latlngList.push(L.latLng(lat, lon, connecteds.total));
            }
          } else if (this.validateDetecteds) {
            let detecteds = this.detecteds.find(el => el.ap_id == element.ip);
            toolTip += `<b>Detectados:</b> ${detecteds ? String(detecteds.total).split('.')[0] : '0'}<br>`;
            if (detecteds) {
              latlngList.push(L.latLng(lat, lon, detecteds.total));
            }
          }

          const marker = L.marker([lat, lon]);
          L.Marker.prototype.options.icon = iconDefault;
          let point = marker.addTo(this.map).bindPopup(toolTip);
          this.points.push(point);
        }
      }
    });

    // Adiciona heatmap apenas para modos específicos
    if (!this.validateCaminhada && !this.validateAreas && latlngList.length > 0) {
      let heatmap = L.heatLayer(latlngList, { radius: this.radius, max: 15 }).addTo(this.map);
      this.heatmap.push(heatmap);
    }
  }

  inicializarForm() {
    this.formulario = this.formBuilder.group({
      dataInicial: [
        moment()
          .format()
          .split('T')[0]
      ],
      hour: [String(moment().hour()) + ':' + '00'],
      mac: [''],
      dataFinal: [
        moment()
          .format()
          .split('T')[0]
      ],
      dateHour: [
        moment()
          .set({ hour: moment().hour(), minute: moment().minute() })
          .format('YYYY-MM-DDTHH:mm')
      ],
      date: [
        moment()
          .format()
          .split('T')[0]
      ],
      period: ['1'],
      allDay: false
    });

    this.dateForm = this.formBuilder.group({
      initialDate: [
        moment()
          .subtract(1, 'day')
          .format()
          .split('T')[0]
      ],
      endDate: [
        moment()
          .format()
          .split('T')[0]
      ]
    });
  }

  changeAllDay() {
    this.allDateInput = this.formulario.controls['allDay'].value;
    this.trocarData(2);
  }

  getConnecteds() {
    this.mapsService
      .getRadiosConnect(this.client.controller_id, this.dataSelecionada)
      .subscribe(data => {
        this.connecteds = data;
        // this.changeImage('Floor_L0');
      })
      .add(() => {
        this.getDetecteds();
        this.getMacs();
      });
  }

  getDetecteds() {
    this.mapsService
      .getRadiosSensor(this.client.radio_prefix, this.dataSelecionada)
      .subscribe(data => {
        this.detecteds = data;
        // this.changeImage('Floor_L0');
      })
      .add(() => {
        this.getAps();
      });
  }

  trocarData(selectType) {
    if (!this.validateCaminhada) {
      if (!this.allDateInput) {
        this.dataSelecionada = moment(this.formulario.controls['dateHour'].value)
          .set({
            hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
            minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
          })
          .format('YYYY-MM-DDTHH:mm:ss');
      } else {
        this.dataSelecionada = moment(this.formulario.controls['date'].value).format('YYYY-MM-DD');
      }

      // Atualiza apenas os dados necessários baseado no modo atual
      if (this.validateConnecteds) {
        this.mapsService.getRadiosConnect(this.client.controller_id, this.dataSelecionada).subscribe(data => {
          this.connecteds = data;
          this.getApsCoords(this.floors.findIndex(f => f.name === this.floorAtual));
        });
      } else if (this.validateDetecteds) {
        this.mapsService.getRadiosSensor(this.client.radio_prefix, this.dataSelecionada).subscribe(data => {
          this.detecteds = data;
          this.getApsCoords(this.floors.findIndex(f => f.name === this.floorAtual));
        });
      }

      // Limpa as camadas apenas para outros modos
      for (let i = 0; i < this.points.length; i++) {
        this.map.removeLayer(this.points[i]);
      }
      this.points = [];

      for (let i = 0; i < this.heatmap.length; i++) {
        this.map.removeLayer(this.heatmap[i]);
      }
      this.heatmap = [];
    } else {
      // Modo caminhada - apenas atualiza as datas sem limpar os APs
      this.initialDate = moment(this.formulario.controls['dateHour'].value)
        .set({
          hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
          minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
        })
        .format('YYYY-MM-DDTHH:mm:ss');

      this.endDate = moment(this.formulario.controls['dateHour'].value)
        .set({
          hour: this.formulario.controls['dateHour'].value.split('T')[0].split(':')[0],
          minute: this.formulario.controls['dateHour'].value.split(':')[1].split(':')[0]
        })
        .add(Number(this.formulario.controls['period'].value), 'hours')
        .format('YYYY-MM-DDTHH:mm:ss');
    }
  }

  changeDates(selectType) {
    let initialDate = moment(this.dateForm.controls['initialDate'].value);
    let endDate = moment(this.dateForm.controls['endDate'].value);

    if (selectType == 1) {
      this.dateForm.controls['endDate'].setValue('');
    }

    if (endDate.isSameOrAfter(initialDate) && this.dateForm.controls['endDate'].value != '') {
      this.initialDate = this.dateForm.controls['initialDate'].value;
      this.endDate = this.dateForm.controls['endDate'].value;

      this.getApConnections();
    }
  }

  updateMap(value) {
    if (value == 1) {
      this.validateConnecteds = true;
      this.validateDetecteds = false;
      this.validateCaminhada = false;
      this.validateAreas = false;
    } else if (value == 2) {
      this.validateDetecteds = true;
      this.validateConnecteds = false;
      this.validateCaminhada = false;
      this.validateAreas = false;
    } else if (value == 3) {
      this.validateCaminhada = true;
      this.validateConnecteds = false;
      this.validateDetecteds = false;
      this.validateAreas = false;
    } else if (value == 4) {
      this.validateAreas = true;
      this.validateConnecteds = false;
      this.validateDetecteds = false;
      this.validateCaminhada = false;
    }

    this.floorAtual = this.floors[0];
    this.changeImage(this.floorAtual.name, false);
  }

  getConnectedsDetectedByHour() {
    this.trocarData(2);
  }

  getMacs() {
    this.mapsService.getMacs(this.client.controller_id).subscribe(data => {
      if (data.length > 0) {
        this.macs = data;
        this.formulario.controls['mac'].setValue(this.macs[0].mac);
      }
    });
  }

  openModal() {
    this.colaboradorForm.reset();
    $('#addColaboradorModal').modal('show');
  }

  salvarColaborador() {
    if (this.colaboradorForm.valid) {
      const mac = this.colaboradorForm.get('mac').value;
      const nome = this.colaboradorForm.get('nome').value;

      this.mapsService.saveMac(this.client.controller_id, mac, nome).subscribe(
        response => {
          // Atualiza a lista de MACs
          this.getMacs();
          // Fecha o modal
          $('#addColaboradorModal').modal('hide');
          // Limpa o formulário
          this.colaboradorForm.reset();
        },
        error => {
          console.error('Erro ao salvar colaborador:', error);
          // Aqui você pode adicionar uma mensagem de erro para o usuário
        }
      );
    }
  }

  buscarSteps() {
    this.loadingSteps = true;

    // Limpa as setas antigas
    for (let i = 0; i < this.stepsArrows.length; i++) {
      this.map.removeLayer(this.stepsArrows[i]);
    }
    this.stepsArrows = [];

    this.mapsService
      .getStepsByMac(this.client.controller_id, this.initialDate, this.endDate, this.formulario.controls['mac'].value)
      .subscribe(
        data => {
          this.allSteps = Array.isArray(data) ? data : [];
          this.currentStepIndex = 0;
          this.hasMoreSteps = this.allSteps.length > this.STEPS_LIMIT;
          this.hasPreviousSteps = false;

          this.steps = this.allSteps.slice(0, this.STEPS_LIMIT);
          this.drawSteps();
        },
        error => {
          console.error('Erro ao buscar dados:', error);
        }
      )
      .add(() => {
        this.loadingSteps = false;
      });
  }
}
