<div class="container" style="max-height: 80vh; overflow-y: auto;">
  <form [formGroup]="form">
    <div class="d-flex" style="margin-bottom: 10px;">
      <div>
        <label style="font-size: 12px;" class=" mb-0">Data Inicial</label>
        <input formControlName="initialDate" type="date" class="form-control" (change)="changeDate(1)" />
      </div>
      <div class="ml-4">
        <label style="font-size: 12px;" class="mb-0">Data Final</label>
        <input formControlName="endDate" type="date" class="form-control" (change)="changeDate(2)" />
      </div>
      <div
        style="border-left: solid 1px rgb(199, 199, 199); margin-top: 20px; margin-left: 20px; padding-top: 8px; padding-left: 10px;"
      >
        <div class="custom-control custom-switch">
          <input
            formControlName="dadosDetalhados"
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            (change)="updateReport()"
          />
          <label class="custom-control-label" for="customSwitch1">Dados detalhados</label>
        </div>
      </div>
      <div class="ml-auto">
        <button
          class="btn btn-md btn-primary mt-4"
          (click)="printArea('printAreaDiv')"
          [disabled]="loadingAuthentications && loading"
        >
          Imprimir Relatório
        </button>
      </div>
    </div>
  </form>

  <div class="card">
    <div id="printAreaDiv">
      <div class="card-header" style="background-color: white;">
        <div class="d-flex p-2">
          <div class="mr-5">
            <img src="../../assets/images/vagalume.png" width="150px" />
          </div>
          <div class="" style="font-size: 12px">
            <h5>{{ clientName }}</h5>
            <p><b>Período:</b> {{ formatDate(initialDateFormated) }} a {{ formatDate(endDateFormated) }}</p>
            <br />
            <p><b>Estabelecimento: </b> {{ clientName }}</p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-center mb-4">
          <p style="font-weight: 500">Usuários Autenticados</p>
        </div>
        <ng-container *ngIf="validateData; else noData">
          <div style="height: 100%;">
            <div class="d-flex mb-4" style="font-weight: 500;">
              <div>
                Dia
              </div>
              <div class="flex-fill ml-2">
                <div class="bg-primary" style="height: 1px; margin-top: 19px;"></div>
              </div>
            </div>
            <div class="">
              <div class="text-center">
                <i
                  class="fas fa-spinner fa-spin text-primary mt-5"
                  [hidden]="!loadingAuthentications"
                  style="font-size: 30px;"
                ></i>
              </div>

              <!-- <canvas baseChart *ngIf="validateAuthenticationsData" [datasets]="authenticationsData" [labels]="authenticationsLabels"
                            [options]="authenticationsOptions" legend="true" chartType="bar" width="100%" height="20">
                        </canvas> -->

              <div #chartContainer *ngIf="validateAuthenticationsData && !validateChart">
                <ng-container>
                  <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [xaxis]="chartOptions.xaxis"
                    [dataLabels]="chartOptions.dataLabels"
                    [grid]="chartOptions.grid"
                    [stroke]="chartOptions.stroke"
                    [title]="chartOptions.title"
                    [tooltip]="chartOptions.tooltip"
                  ></apx-chart>
                </ng-container>
              </div>

              <img *ngIf="validateChart" [src]="chartImg" />
            </div>
          </div>

          <div class="row mt-4" style="font-size: 14px;">
            <div class="col-md-4">
              <div class="card" style="padding: 15px;">
                <span class="text-primary mb-2">Total Autenticados por período</span>
                <ng-container *ngIf="!loadingAuthenticatedTotal; else loadingSecondary">
                  <b>{{ authenticatedTotal }}</b>
                </ng-container>

                <hr class="mt-2 mb-2" />

                <ng-container *ngIf="!loadingNewTotal; else loadingSecondary">
                  <span class="text-primary mb-2">Total Novos por período</span>
                  <b>{{ newTotal }}</b>
                </ng-container>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card" style="height: 100%; padding: 15px;">
                <ng-container *ngIf="validateAuthenticationsData; else carregando">
                  <span class="text-primary mb-2">Pico de Usuários Autenticados</span>
                  <table class="table table-sm">
                    <thead>
                      <tr style="font-size: 12px;">
                        <th>Dia</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{ formatDate(maxAuthenticationsDay) }}</td>
                        <td>{{ maxAuthenticationsTotal }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card" style="height: 100%; padding: 15px;">
                <ng-container *ngIf="validateAuthenticationsData; else carregando">
                  <span class="text-primary mb-2">Pico de Usuários Novos</span>
                  <table class="table table-sm">
                    <thead>
                      <tr style="font-size: 12px;">
                        <th>Dia</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{ formatDate(maxNewsDay) }}</td>
                        <td>{{ maxNewsTotal }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="validateDataByDay" class="row mt-4">
          <div class="col-md-6">
            <div class="card" style="padding: 15px;">
              <span class="text-primary mb-2">Usuários Autenticados por dia</span>
              <table class="table table-sm">
                <thead>
                  <tr style="font-size: 12px;">
                    <th>Dia</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let col of authenticationsLabels; let i = index">
                    <td>{{ formatDate(col) }}</td>
                    <td>{{ authenticationsDataBox[i] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card" style="padding: 15px;">
              <span class="text-primary mb-2">Usuários Novos por dia</span>
              <table class="table table-sm">
                <thead>
                  <tr style="font-size: 12px;">
                    <th>Dia</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let col of authenticationsLabels; let i = index">
                    <td>{{ formatDate(col) }}</td>
                    <td>{{ newsDataBox[i] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="text-center mb-4 mt-4">
          <p style="font-weight: 500">Usuários por Faixa Etária e Gênero</p>
        </div>

        <div class="mb-4">
          <div class="d-flex" style="font-weight: 500;">
            <div>
              Faixa Etária e Gênero
            </div>
            <div class="flex-fill ml-2">
              <div class="bg-primary" style="height: 1px; margin-top: 19px;"></div>
            </div>
          </div>

          <div class="d-flex justify-content-center pt-4 pb-2">
            <ng-container *ngIf="!loading; else carregando">
              <ng-container *ngIf="ageGroupGender.series.length > 0; else noData">
                <apx-chart
                  *ngIf="validateAgeGroupGenderData"
                  [series]="ageGroupGender.series"
                  [chart]="ageGroupGender.chart"
                  [dataLabels]="ageGroupGender.dataLabels"
                  [plotOptions]="ageGroupGender.plotOptions"
                  [yaxis]="ageGroupGender.yaxis"
                  [xaxis]="ageGroupGender.xaxis"
                  [legend]="ageGroupGender.legend"
                  [colors]="ageGroupGender.colors"
                  [grid]="ageGroupGender.grid"
                ></apx-chart>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="row page-break">
          <div class="col-md-6 mb-4">
            <div class="d-flex" style="font-weight: 500;">
              <div>
                Gênero
              </div>
              <div class="flex-fill ml-2">
                <div class="bg-primary" style="height: 1px; margin-top: 19px;"></div>
              </div>
            </div>

            <div class="d-flex justify-content-center pt-4 pb-4">
              <ng-container *ngIf="!loading; else carregando">
                <ng-container *ngIf="genderData.series.length > 0; else noData">
                  <apx-chart
                    *ngIf="validateGenderPieChart"
                    [series]="genderData.series"
                    [chart]="genderData.chart"
                    [labels]="genderData.labels"
                    [responsive]="genderData.responsive"
                  ></apx-chart>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div class="col-md-6 mb-4">
            <div class="d-flex" style="font-weight: 500;">
              <div>
                Faixa Etária
              </div>
              <div class="flex-fill ml-2">
                <div class="bg-primary" style="height: 1px; margin-top: 19px;"></div>
              </div>
            </div>

            <div class="card-body pt-4 pb-4">
              <ng-container *ngIf="!loading; else carregando">
                <ng-container *ngIf="ageGroupData.series.length > 0; else noData">
                  <apx-chart
                    *ngIf="validateAgeGroupData"
                    [series]="ageGroupData.series"
                    [chart]="ageGroupData.chart"
                    [dataLabels]="ageGroupData.dataLabels"
                    [yaxis]="ageGroupData.yaxis"
                    [legend]="ageGroupData.legend"
                    [stroke]="ageGroupData.stroke"
                    [tooltip]="ageGroupData.tooltip"
                    [xaxis]="ageGroupData.xaxis"
                  ></apx-chart>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div #chartGenderContainer>
          <div class="text-center mb-4" *ngIf="chartGenderImg == '' && medias.length > 0">
            <p style="font-weight: 500">Mídias</p>
          </div>

          <ng-container *ngIf="chartGenderImg == ''">
            <div class="page-break" *ngFor="let col of medias; let i = index">
              <ng-container *ngIf="!validateGenderChart">
                <div class="d-flex" style="font-weight: 500;">
                  <div>
                    {{ col.name }}
                  </div>
                  <div class="flex-fill ml-2">
                    <div class="bg-primary" style="height: 1px; margin-top: 19px;"></div>
                  </div>
                </div>

                <div class="row mt-4 mb-4" style="font-size: 14px; page-break-inside: avoid; page-break-after: auto;">
                  <div class="col-md-4">
                    <div class="card" style="padding: 15px;">
                      <span class="text-primary mb-2">Total de exibições</span>
                      <ng-container *ngIf="!loadingAuthenticatedTotal; else loadingSecondary">
                        <b style="font-size: 20px">{{ mediasTotalCount[i] }}</b>
                      </ng-container>
                    </div>

                    <div class="card mt-2" style="padding: 15px;">
                      <ng-container *ngIf="!loadingDailyMediaData; else carregando">
                        <span class="text-primary mb-2">Pico total de exibições</span>
                        <table class="table table-sm">
                          <thead>
                            <tr style="font-size: 12px;">
                              <th>Dia</th>
                              <th>Total</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>{{ datesMediasPeak[i] }}</td>
                              <td>{{ mediasPeak[i] }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col-md-8">
                    <div class="card" style="height: 100%; padding: 0px;">
                      <ng-container *ngIf="genderGraphValid">
                        <apx-chart
                          [series]="chartGenderData[i].series"
                          [chart]="chartGenderData[i].chart"
                          [dataLabels]="chartGenderData[i].dataLabels"
                          [plotOptions]="chartGenderData[i].plotOptions"
                          [yaxis]="chartGenderData[i].yaxis"
                          [xaxis]="chartGenderData[i].xaxis"
                          [legend]="chartGenderData[i].legend"
                          [colors]="chartGenderData[i].colors"
                          [grid]="chartGenderData[i].grid"
                        ></apx-chart>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #carregando>
  <div class="text-center">
    <i class="fas fa-spinner fa-spin text-primary" style="font-size: 20px;"></i>
  </div>
</ng-template>

<ng-template #noData>
  <div class="text-center">
    Não há dados disponíveis.
  </div>
</ng-template>

<ng-template #loadingSecondary>
  <div class="text-left">
    <i class="fas fa-spinner fa-spin text-primary" style="font-size: 14px;"></i>
  </div>
</ng-template>
