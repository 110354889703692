<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation" style="font-size: 12px;">
    <button
      class="nav-link active"
      id="home-tab"
      data-toggle="tab"
      data-target="#mapa"
      type="button"
      role="tab"
      aria-controls="home"
      aria-selected="true"
    >
      Mapa
    </button>
  </li>
  <li class="nav-item" role="presentation" style="font-size: 12px;">
    <button
      class="nav-link"
      id="profile-tab"
      data-toggle="tab"
      data-target="#dispositivos"
      type="button"
      role="tab"
      aria-controls="profile"
      aria-selected="false"
      (click)="reloadChart()"
    >
      Estações detectadas
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="mapa" role="tabpanel" aria-labelledby="home-tab">
    <form [formGroup]="formulario">
      <div class="macs-inputs">
        <div class="d-flex"></div>
      </div>

      <div class="dateInputs">
        <div class="d-flex">
          <div>
            <label style="font-size: 12px;" class="mb-0">Dia e hora</label>
            <ng-container *ngIf="validateCaminhada || !allDateInput">
              <input
                style="font-size: 12px;"
                type="datetime-local"
                class="form-control"
                formControlName="dateHour"
                (change)="trocarData(2)"
              />
            </ng-container>

            <ng-container *ngIf="!validateCaminhada && allDateInput">
              <input
                style="font-size: 12px;"
                type="date"
                class="form-control"
                formControlName="date"
                (change)="trocarData(2)"
              />
            </ng-container>
          </div>

          <div class="custom-control custom-checkbox mt-4 ml-2" *ngIf="!validateCaminhada">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customCheck1"
              formControlName="allDay"
              (change)="changeAllDay()"
            />
            <label class="custom-control-label mr-2" for="customCheck1">Dia inteiro</label>
          </div>

          <div class="ml-2" *ngIf="validateCaminhada">
            <label style="font-size: 12px;" class="mb-0">Período</label>
            <select formControlName="period" style="font-size: 12px;" class="form-control" (change)="trocarData(2)">
              <option value="" disabled>Selecione uma opção</option>
              <option value="1">1 Hora</option>
              <option value="3">3 Horas</option>
              <option value="6">6 Horas</option>
              <option value="12">12 Horas</option>
            </select>
          </div>

          <div class="ml-2 mr-2" *ngIf="validateCaminhada">
            <label style="font-size: 12px;" class=" mb-0">Selecione o mac</label><br />
            <select style="font-size: 12px;" class="form-control" formControlName="mac" (change)="getStepsByMac()">
              <option value="" disabled>Selecione o funcionário</option>
              <option *ngFor="let col of macs" value="{{ col.mac }}">{{ col.name }}</option>
            </select>
          </div>

          <div class="ml-2" *ngIf="validateCaminhada">
            <label style="font-size: 12px;" class="mb-0">&nbsp;</label><br />
            <div class="d-flex align-items-end">
              <button class="btn btn-primary btn-sm mr-2" (click)="buscarSteps()" [disabled]="loadingSteps">
                <span *ngIf="!loadingSteps">Buscar</span>
                <span
                  *ngIf="loadingSteps"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>

              <button class="btn btn-primary btn-sm" (click)="openModal()">
                Adicionar Colaborador
              </button>
            </div>
          </div>

          <div class="ml-2" *ngIf="validateCaminhada">
            <label style="font-size: 12px;" class="mb-0">&nbsp;</label><br />
            <button class="btn btn-primary btn-sm mr-2" [disabled]="!hasPreviousSteps" (click)="showPreviousSteps()">
              Passos anteriores
            </button>
            <button class="btn btn-primary btn-sm" [disabled]="!hasMoreSteps" (click)="showNextSteps()">
              Próximos passos
            </button>
          </div>
        </div>
      </div>
    </form>

    <ul class="nav nav-pills justify-content-center mt-4" style="font-size: 12px;">
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer"
          (click)="updateMap(1)"
          [ngClass]="validateConnecteds ? 'active bg-primary' : 'bgSecondary'"
          >Conectados</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer;"
          (click)="updateMap(2)"
          [ngClass]="validateDetecteds ? 'active bg-primary' : 'bgSecondary'"
          >Detectados</a
        >
      </li>
      <li class="nav-item" *ngIf="validateSteps">
        <a
          class="nav-link"
          style="cursor: pointer;"
          (click)="updateMap(3)"
          [ngClass]="validateCaminhada ? 'active bg-primary' : 'bgSecondary'"
          >Caminhada</a
        >
      </li>
      <li class="nav-item" *ngIf="userText == 'ROOT'">
        <a
          class="nav-link"
          style="cursor: pointer"
          (click)="updateMap(4)"
          [ngClass]="validateAreas ? 'active bg-primary' : 'bgSecondary'"
          >Áreas</a
        >
      </li>
    </ul>
    <div class="map-container">
      <div class="map-frame">
        <div id="map">
          <select
            class="form-control form-control-sm"
            id="refreshButton"
            style="width: 200px; height: 40px;"
            (change)="changeImage($event.target.value, true)"
          >
            <option *ngFor="let col of floors; let i = index" value="{{ col.name }}">
              {{ col.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="dispositivos" role="tabpanel" aria-labelledby="profile-tab">
    <div class="card mb-0 mt-2" style="height: 100%;">
      <div class="card-header" style="background-color: white; font-weight: 500;">
        <div class="d-flex">
          <div class="mt-2">
            Estações detectadas
            <i
              class="fas fa-question-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Quantidade de estações detectadas no período"
            ></i>
          </div>

          <div class="ml-auto">
            <div>
              <form [formGroup]="dateForm">
                <div class="d-flex">
                  <div style="font-size: 12px;">
                    <label class=" mb-0">Data Inicial</label>
                    <input
                      formControlName="initialDate"
                      style="font-size: 12px;"
                      type="date"
                      class="form-control"
                      (change)="changeDates(1)"
                    />
                  </div>
                  <div class="ml-2" style="font-size: 12px;">
                    <label class=" mb-0">Data Final</label>
                    <input
                      formControlName="endDate"
                      style="font-size: 12px;"
                      type="date"
                      class="form-control"
                      (change)="changeDates(2)"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="!loadingConnections; else loading">
          <ng-container *ngIf="validateConnections; else noRealtimeData">
            <apx-chart
              [series]="chartOptionsConnections.series"
              [chart]="chartOptionsConnections.chart"
              [xaxis]="chartOptionsConnections.xaxis"
              [colors]="chartOptionsConnections.colors"
              [dataLabels]="chartOptionsConnections.dataLabels"
              [grid]="chartOptionsConnections.grid"
              [stroke]="chartOptionsConnections.stroke"
              [title]="chartOptionsConnections.title"
            ></apx-chart>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #loading>
      <div class="text-center mt-2">
        <i class="fas fa-spinner fa-spin text-primary" style="width: 20px;"></i>
      </div>
    </ng-template>

    <ng-template #noRealtimeData>
      <div class="text-center">
        Sem dados disponíveis.
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="addColaboradorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addColaboradorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addColaboradorModalLabel">Adicionar Colaborador</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="colaboradorForm">
          <div class="form-group">
            <label for="mac">MAC</label>
            <input
              type="text"
              class="form-control"
              id="mac"
              formControlName="mac"
              placeholder="Ex: 00:11:22:33:44:55"
            />
          </div>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input
              type="text"
              class="form-control"
              id="nome"
              formControlName="nome"
              placeholder="Nome do colaborador"
            />
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarColaborador()">Salvar</button>
      </div>
    </div>
  </div>
</div>
